<template>
  <div>
    <page-content>
      当前选中为：{{ item }}
      <el-button
        type="primary"
        size="mini"
        @click="addVisible = true"
      >
        新 建
      </el-button>
      <el-button
        type="primary"
        size="mini"
        :disabled="item === ''"
        @click="editVisible = true"
      >
        编 辑
      </el-button>
      <el-button
        size="mini"
        type="danger"
        :disabled="item === ''"
        @click="closeTip=true"
      >
        删 除
      </el-button>
    </page-content>
    <page-content v-loading="loading">
      <el-tree
        :data="menuList"
        :props="defaultProps"
        node-key="navigationId"
        highlight-current
        @node-click="handleNodeClick"
      />
    </page-content>

    <!--    删除菜单-->
    <el-dialog
      title="删除确认"
      :visible.sync="closeTip"
    >
      <span>确认删除该权限？删除后该权限下的子权限数据将一并删除。</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="closeTip = false"
        >
          取 消
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="delItem"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!--    新建菜单-->
    <el-dialog
      title="新建"
      :visible.sync="addVisible"
    >
      <el-form
        ref="addForm"
        :model="newNavigation"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item
          label="导航类型"
          prop="type"
        >
          <el-select
            v-model="newNavigation.type"
            placeholder="请选择导航类型"
            style="width: 100%"
            @change="changeType"
          >
            <el-option
              v-for="item in types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="上级导航"
          prop="pid"
        >
          <el-select
            v-model="newNavigation.pid"
            placeholder="请选择上级导航"
            style="width: 100%"
          >
            <el-option
              v-for="item in menuGroupList"
              :key="item.navigationId"
              :label="item.name"
              :value="item.navigationId"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="导航名称"
          prop="name"
        >
          <el-input
            v-model="newNavigation.name"
            placeholder="请输入导航名称"
          />
        </el-form-item>
        <el-form-item label="路由地址">
          <el-input
            v-model="newNavigation.router"
            placeholder="请输入路由地址"
          />
        </el-form-item>
        <el-form-item label="导航图标">
          <el-input
            v-model="newNavigation.icon"
            placeholder="请输入导航图片地址"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="closeDialog('addForm')">
            取消
          </el-button>
          <el-button
            type="primary"
            @click="addNavigation"
          >
            确定
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--    编辑菜单-->
    <el-dialog
      title="编辑"
      :visible.sync="editVisible"
    >
      <el-form
        ref="editForm"
        :model="editNavigation"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item
          label="导航类型"
          prop="type"
        >
          <el-select
            v-model="editNavigation.type"
            placeholder="请选择导航类型"
            style="width: 100%"
            @change="editNavigation.pid = undefined"
          >
            <el-option
              v-for="item in types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="上级导航"
          prop="pid"
        >
          <el-select
            v-model="editNavigation.pid"
            placeholder="请选择上级导航"
            style="width: 100%"
          >
            <el-option
              v-for="item in menuGroupList"
              :key="item.navigationId"
              :label="item.name"
              :value="item.navigationId"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="导航名称"
          prop="name"
        >
          <el-input
            v-model="editNavigation.name"
            placeholder="请输入导航名称"
          />
        </el-form-item>
        <el-form-item label="路由地址">
          <el-input
            v-model="editNavigation.router"
            placeholder="请输入路由地址"
          />
        </el-form-item>
        <el-form-item label="导航图标">
          <el-input
            v-model="editNavigation.icon"
            placeholder="请输入导航图片地址"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="editVisible = false">
            取消
          </el-button>
          <el-button
            type="primary"
            @click="submitEdit"
          >
            确定
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'NavControl',
  data() {
    return {
      menuList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      item: '',		// 当前选中的 标签名称
      pid: '',			// 当前选中的 标签父ID
      id: '',			// 当前选中的 标签ID
      openArr: [],
      loading: false,
      closeTip: false,
      // 添加
      addTip: false,
      addType: '',			// 1同级   2子级
      menu_name: '',
      menu_router: '',
      itemObj: {},			// 当前选中节点对象
      type: '',
      menu_icon: '',
      addVisible: false,
      newNavigation: {
        type: '',
        pid: '',
        name: '',
        icon: '',
        router: ''
      },
      types: [
        {
          label: '菜单组',
          value: 0
        },
        {
          label: '菜单',
          value: 1
        }
      ],
      menuGroupList: [],
      rules: {
        type: [
          { required: true, message: '导航类型不能为空', trigger: 'blur' }
        ],
        pid: [
          { required: true, message: '上级导航不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '导航名称不能为空', trigger: 'blur' }
        ]
      },
      editVisible: false,
      editNavigation: {}
    }
  },
  mounted() {
    this.getList()
    this.getMenuGroupList()
  },
  methods: {
    // 获取MenuList
    getList() {
      let _this = this
      this.loading = true
      _this.$axios.post(_this.$api.nav.getlist).then(res => {
        this.loading = false

        if (res.code === 0) {
          _this.menuList = res.data
        }
      })
    },
    // 获取菜单组列表
    async getMenuGroupList() {
      const res = await this.$axios.post(this.$api.nav.getMenuGroupList)
      this.menuGroupList = res.data
      this.menuGroupList.unshift({
        name: '无',
        navigationId: 0
      })
    },
    // 点击标签  获取信息
    handleNodeClick(data) {
      this.pid = ''
      this.item = data.name
      this.pid = data.pid
      this.id = data.navigationId
      this.itemObj = data
      this.editNavigation = data
    },
    // 删除
    delItem() {
      let _this = this
      _this.$axios.post(_this.$api.nav.del, {
        navigationId: _this.id
      }).then((res) => {
        let r = res
        if (r.code === 0) {
          _this.$message.success('删除成功！')
          _this.item = ''
          _this.pid = ''
          _this.id = ''
          _this.getList()
          _this.closeTip = false
        } else {
          _this.$message.error(r.msg)
        }
      })
    },
    addItem() {
      let _this = this
      _this.$axios.post(_this.type === 'edit' ? _this.$api.nav.update : _this.$api.nav.add, {
        menu_id: _this.type === 'edit' ? _this.id : undefined,
        name: _this.menu_name,
        router: _this.menu_router,
        pid: _this.type === 'edit' ? _this.pid : _this.addType === 1 ? _this.pid : _this.id,
        icon: _this.menu_icon
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          _this.$message.success('添加成功！')
          _this.getList()
          _this.addTip = false
        } else {
          _this.$message.error(r.error_msg)
        }
      })
    },
    // 设置导航图片
    setImg() {

    },
    // 新增菜单
    addNavigation() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.nav.add, this.newNavigation).then(res => {
            this.$message.success('新建成功！')
            this.closeDialog('addForm')
            this.getList()
          })
        }
      })
    },
    // 关闭Dialog
    closeDialog(formName) {
      this.addVisible = false
      this.$refs[formName].resetFields()
    },

    // 导航类型改变
    changeType(val) {
      this.newNavigation.pid = ''
    },

    // 编辑
    submitEdit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.nav.edit, this.editNavigation).then(res => {
            this.$message.success('编辑成功！')
            this.editVisible = false
            this.getList()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.nav-control{
  overflow: hidden;
  position: relative;
}
.nav-control .navs{
  width:300px;
  float:left;
}
.fix-box{
  width:300px;
  height:200px;
  padding:30px;
  background:#EDF2F6;
  position:fixed;
  left:50%;
  top:150px;
}
.tips p{
  margin: 15px 0;
}
.tips p span{
  display: inline-block;
  width:100px;
  text-align: right;
  margin: 0 30px;
}
.load-btn{
  margin-left: 0;
  margin-top: 20px;
}
</style>
