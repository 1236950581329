var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "page-content",
        [
          _vm._v(" 当前选中为：" + _vm._s(_vm.item) + " "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  _vm.addVisible = true
                },
              },
            },
            [_vm._v(" 新 建 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                disabled: _vm.item === "",
              },
              on: {
                click: function ($event) {
                  _vm.editVisible = true
                },
              },
            },
            [_vm._v(" 编 辑 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "danger",
                disabled: _vm.item === "",
              },
              on: {
                click: function ($event) {
                  _vm.closeTip = true
                },
              },
            },
            [_vm._v(" 删 除 ")]
          ),
        ],
        1
      ),
      _c(
        "page-content",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("el-tree", {
            attrs: {
              data: _vm.menuList,
              props: _vm.defaultProps,
              "node-key": "navigationId",
              "highlight-current": "",
            },
            on: { "node-click": _vm.handleNodeClick },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "删除确认", visible: _vm.closeTip },
          on: {
            "update:visible": function ($event) {
              _vm.closeTip = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v("确认删除该权限？删除后该权限下的子权限数据将一并删除。"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.closeTip = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.delItem },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "新建", visible: _vm.addVisible },
          on: {
            "update:visible": function ($event) {
              _vm.addVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.newNavigation,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "导航类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择导航类型" },
                      on: { change: _vm.changeType },
                      model: {
                        value: _vm.newNavigation.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.newNavigation, "type", $$v)
                        },
                        expression: "newNavigation.type",
                      },
                    },
                    _vm._l(_vm.types, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上级导航", prop: "pid" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择上级导航" },
                      model: {
                        value: _vm.newNavigation.pid,
                        callback: function ($$v) {
                          _vm.$set(_vm.newNavigation, "pid", $$v)
                        },
                        expression: "newNavigation.pid",
                      },
                    },
                    _vm._l(_vm.menuGroupList, function (item) {
                      return _c("el-option", {
                        key: item.navigationId,
                        attrs: { label: item.name, value: item.navigationId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "导航名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入导航名称" },
                    model: {
                      value: _vm.newNavigation.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.newNavigation, "name", $$v)
                      },
                      expression: "newNavigation.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "路由地址" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入路由地址" },
                    model: {
                      value: _vm.newNavigation.router,
                      callback: function ($$v) {
                        _vm.$set(_vm.newNavigation, "router", $$v)
                      },
                      expression: "newNavigation.router",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "导航图标" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入导航图片地址" },
                    model: {
                      value: _vm.newNavigation.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.newNavigation, "icon", $$v)
                      },
                      expression: "newNavigation.icon",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog("addForm")
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addNavigation },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.editVisible },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                model: _vm.editNavigation,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "导航类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择导航类型" },
                      on: {
                        change: function ($event) {
                          _vm.editNavigation.pid = undefined
                        },
                      },
                      model: {
                        value: _vm.editNavigation.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.editNavigation, "type", $$v)
                        },
                        expression: "editNavigation.type",
                      },
                    },
                    _vm._l(_vm.types, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上级导航", prop: "pid" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择上级导航" },
                      model: {
                        value: _vm.editNavigation.pid,
                        callback: function ($$v) {
                          _vm.$set(_vm.editNavigation, "pid", $$v)
                        },
                        expression: "editNavigation.pid",
                      },
                    },
                    _vm._l(_vm.menuGroupList, function (item) {
                      return _c("el-option", {
                        key: item.navigationId,
                        attrs: { label: item.name, value: item.navigationId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "导航名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入导航名称" },
                    model: {
                      value: _vm.editNavigation.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editNavigation, "name", $$v)
                      },
                      expression: "editNavigation.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "路由地址" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入路由地址" },
                    model: {
                      value: _vm.editNavigation.router,
                      callback: function ($$v) {
                        _vm.$set(_vm.editNavigation, "router", $$v)
                      },
                      expression: "editNavigation.router",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "导航图标" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入导航图片地址" },
                    model: {
                      value: _vm.editNavigation.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.editNavigation, "icon", $$v)
                      },
                      expression: "editNavigation.icon",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.editVisible = false
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitEdit },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }